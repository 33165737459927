@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;700&display=swap");
@import "~@flaticon/flaticon-uicons/css/all/all";
body {
  font-family: "Fira Sans", sans-serif;
}

.md-1000 {
  max-width: 1000px;
}
.md-800 {
  max-width: 800px;
}
.ledger-header {
  background-color: #e8e6e6ad;
}
.ledger-modal {
  .modal-content {
    height: 100%;
  }

  .ledger-table {
    tr {
      cursor: default;
    }
    td {
      padding-top: 4px;
      padding-bottom: 3px;
    }

    th {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
.btns-add {
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
    display: inline-block;
    margin: 0 10px 5px 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

.btn-light {
  background: #a2e8c2;
  color: #288850;

  &:hover,
  &:focus,
  &:active {
    background: #8adcaf;
    color: #288850;
  }
}
.values_exp {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
    display: inline-block;
    margin: 0 0 10px 0;
    background: #63a581;
    border-radius: 0;
    padding: 6px 18px 6px 18px;
    color: #fff;
    position: relative;
    min-width: 25%;
    border-radius: 10px;
    margin-right: 6px;

    &.active {
      background: #1d6f42;
    }

    label {
      font-size: 14px;
      display: block;
      font-weight: 400;
      margin-bottom: 0;
      text-transform: uppercase;
    }

    span {
      font-size: 24px;
      display: block;
      font-weight: 700;
    }

    svg {
      position: absolute;
      left: 5px;
      top: 16px;
      fill: #aee1c3;
      height: 34px;
    }
  }
}

.values {
  margin: 0;
  padding: 0;

  li {
    list-style-type: none;
    display: inline-block;
    margin: 0 0 10px 0;
    /* background: #F3F3F3; */
    border-radius: 0;
    padding: 6px 18px 6px 38px;
    color: #4e4e4e;
    position: relative;
    min-width: 25%;

    &:nth-child(1) {
      border-bottom: 3px solid #20a8f2;
      background-color: #20a8f2;
      svg {
        fill: #fff;
      }
      label {
        color: #fff;
      }
      span {
        color: #fff;
      }
      &.active {
        border-color: #332e2e;
      }
      &:hover {
        background: #20a8f2;
      }
    }
    &:nth-child(2) {
      border-bottom: 3px solid #fb7e00;
      background-color: #fb7e00;
      svg {
        fill: #fff;
      }
      label {
        color: #fff;
      }
      span {
        color: #fff;
      }
      &.active {
        background: #fb7e00;
        border-color: #332e2e;
      }
      &:hover {
        background: #fb7e00;
      }
    }
    &:nth-child(3) {
      margin-right: 0;
      border-bottom: 3px solid #7ecd00;
      background: #7ecd00;
      svg {
        fill: #fff;
      }
      label {
        color: #fff;
      }
      span {
        color: #fff;
      }
      &.active {
        background: #7ecd00;
        border-color: #332e2e;
      }
      &:hover {
        background: #7ecd00;
      }
    }

    &:hover {
      color: #2e2e2d;
      background: #e6fff1;

      /* svg {
                fill: #288850;
            } */
    }

    label {
      font-size: 14px;
      display: block;
      font-weight: 400;
      margin-bottom: 0;
      text-transform: uppercase;
    }

    span {
      font-size: 24px;
      display: block;
      font-weight: 700;
    }

    svg {
      position: absolute;
      left: 5px;
      top: 16px;
      fill: #aee1c3;
      height: 34px;
    }
  }
}

.calendar {
  background: url(../images/calendar.svg) no-repeat right center;
  padding-right: 45px;
  background-color: #fff;
}
.cursor-pointer {
  cursor: pointer;
}
.grn-bg-frm {
  background: #7ed5a2;
  border-radius: 10px;
  padding: 20px 20px 0 20px;

  .formlabel {
    background: #67b38d;
    border-radius: 10px;
    padding: 20px;
    color: #fff;
    margin-bottom: 20px;

    label {
      display: block;
      margin-bottom: 0;
    }

    span {
      display: block;
      font-weight: 700;
    }
  }
}

.grn-bg-frm2 {
  background: #67b38d;
  border-radius: 10px;
  padding: 20px 20px 0 20px;

  .formlabel {
    background: #67b38d;
    border-radius: 10px;
    padding-left: 20px;
    color: #fff;
    margin-bottom: 20px;

    label {
      display: block;
      margin-bottom: 0;
    }

    span {
      display: block;
      font-weight: 700;
    }
  }
}
.text-grey{
  color: #cacaca;
}
.gst-table{
  border: 0.5px solid #cacaca;
}
.gst-table td {
  border: 0.5px solid #cacaca;
}
// .gst-table td:nth-child(5),
// .gst-table td:nth-child(6),
// .gst-table td:nth-child(7),
// .gst-table td:nth-child(8),
// .gst-table td:nth-child(9),
// .gst-table td:nth-child(10) {
//   border: 0.5px solid #cacaca;
// }
.gst-table th:nth-child(1),
.gst-table th:nth-child(2),
.gst-table th:nth-child(9) {
  border: 0.5px solid #cacaca;
}
.gst-table th {
  padding: 0.25rem 0.5rem;
}
.gst-table td {
  padding: 0.25rem 0.5rem;
}
.grey-bg-frm {
  background: #f6f5f5;
  border-radius: 10px;
  padding: 20px 20px 10px 20px;
}

.grey-border-frm {
  border-color: #cacaca !important;
}

.table-icons {
  color: #288850;

  i {
    margin: 0 5px;
    cursor: pointer;
  }
}

.md-700 {
  max-width: 700px;
}

.modal-content {
  .modal-header {
    h5 {
      font-size: 16px;
      font-weight: 500;
    }

    .close {
      font-size: 16px;
      top: 5px;
      right: 8px;
    }
  }

  .modal-footer {
    border-top: 0;
  }
}

span.bold {
  font-weight: 700;
}

.status-mess {
  color: #f24343;
  display: block;
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 0;
}

span.status {
  width: 100px;
  display: inline-block;
  border-radius: 6px;
  padding: 3px 0;
  text-align: center;
  margin-bottom: 2px;

  &.partially {
    background: #ffeeb8;
    color: #9a5454;
  }

  &.pending {
    background: #fbdaca;
    color: #9a5454;
  }

  &.sent {
    background: #fbdaca;
    color: #9a5454;
  }

  &.converted {
    background: #fbdaca;
    color: #9a5454;
  }

  &.expired {
    background: #fbdaca;
    color: #9a5454;
  }
}
.list-circle {
  border-radius: 50% !important;
  width: 40px;
  height: 40px;
}
.table-button {
  button.normal-btn {
    border-radius: 6px;
    background: #eaeaea;
    border: 0 none;
    padding: 5px 12px;
    color: #1a3254;
    &:hover {
      background: #bec7d5;
    }
  }
  button.round-btn {
    border-radius: 6px;
    background: #eaeaea;
    margin-right: 10px;
    border: 0 none;
    border-radius: 50% !important;
    width: 40px;
    height: 40px;
    text-align: center;
    &.whatsapp {
      background: #01d46b;
      svg {
        fill: #fff;
      }
      &:hover {
        background: #0eb561;
      }
    }
    svg {
      fill: #1a3254;
    }
    &:hover {
      background: #bec7d5;
    }

    &.green {
      &:hover {
        background: #59cc8d;
      }
    }

    &.green:hover {
      background: #7ae9ac;
    }

    &.red {
      &:hover {
        background: #f1bebe;
      }
    }

    &.red:hover {
      background: #fcc6c6;
    }

    &.yellow {
      &:hover {
        background: #dfc388;
      }
    }

    &.yellow:hover {
      background: #fde3ab;
    }

    &.orange {
      &:hover {
        background: #ecb18a;
      }

      a {
        color: #955124;
      }
    }
    &.orange:hover {
      background: #ffc097;
    }
  }
}

.table-responsive {
  overflow-x: auto !important;
}

.radio-btns {
  background: #dbdbdb;
  border-radius: 10px;
  padding: 10px 15px;

  label {
    margin-bottom: 0;
    font-weight: 400;
  }

  fieldset {
    margin-bottom: 0 !important;

    .form-control {
      background: none;
    }
  }
}

.user-det {
  background: #f6f6f6;
  border-radius: 6px;
  padding: 15px 15px;

  label {
    color: #bbbbbb;
  }
}

.user-settings {
  .card-header {
    background: #f6f6f6;
  }
}

/*dash board*/
.cstm-card {
  .card-header {
    padding: 1rem 1.25rem;
    font-weight: 500;
  }

  .card-body {
    min-height: 405px;
  }
}

.qck-link {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 12px;

    a {
      label {
        display: block;
        color: #343434;
        text-align: center;
        margin-top: 10px;
      }

      span {
        display: block;
        background: #e4fff0;
        border-radius: 12px;
        padding: 20px;
      }

      &:hover {
        span {
          background: #c4e9d5;
        }
      }
    }
  }
}
.qck-link1 {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: right;

  li {
    display: inline-block;
    margin: 0 0 0 15px;

    a {
      display: block;

      label {
        display: block;
        color: #343434;
        text-align: center;
        margin-top: 10px;
        font-size: 13px;
      }

      span {
        display: block;
        background: #eaeaea;
        border-radius: 12px;
        display: flex;
        height: 65px;
        width: 65px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 1px solid #eaeaea;
        box-shadow: 0px 1px 2px #00000029;
        margin: 0 auto;
      }

      &:hover {
        span {
          background: #e9e9e9;
          border: 1px solid #1a3254;
        }
      }
    }
  }
}

/*invoice create*/
.form-head {
  background: #efefef;
  border-radius: 6px;
  padding: 15px 15px 0 15px;
}

.add-row {
  border-bottom: 1px solid #1d6f41;
  text-align: center;
  .login {
    background: #fff !important;
    color: #000000;
    width: 10%;
    position: relative;
    bottom: -14px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .btn {
    background: #fff;
    border: 1px solid #1d6f41;
    color: #1d6f41;
    width: 200px;
    position: relative;
    bottom: -20px;

    i {
      font-size: 18px;
      position: relative;
      top: 3px;
    }

    &:hover {
      background: #1d6f41;
      color: #fff;
    }
  }
}

.tbl-btn {
  color: #1d6f41;
  background: #ebebeb;
  border: 1px solid #d8d8d8;
  border-radius: 6px;

  &:hover {
    background: #d6ffe7;
    color: #239155;
  }
}

.total {
  background: #efefef;
  padding: 15px;
  border-radius: 14px;

  ul {
    margin: 0;
    padding: 0;

    li {
      display: block;
      border-bottom: 1px solid #c2c2c2;
      padding: 5px 0;

      &:last-child {
        border: 0;
      }

      label {
        display: inline-block;
        float: left;
      }

      span {
        display: block;
        text-align: right;
        margin-left: 150px;
      }
    }
  }
}

.count {
  color: #1d6f42;
}

.form-wrap {
  .header {
    h4 {
      display: inline-block;
      margin-right: 25px;
    }
    .form-inline {
      display: inline-block;
    }
  }
}

.cstm-search {
  .search-label {
    float: none;
    margin-bottom: 0;
  }
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #f2f2f2;
  opacity: 1;
}

.cstm-tbl {
  .form-control {
    height: 35px;
  }
  .btn {
    padding: 7px 0.75rem;
  }
}

.table {
  font-size: 15px;
}
#sidebar-menu ul li a {
  font-size: 15px !important;
  /* font-weight: 500 !important; */
}

.package {
  h1 {
    font-size: 36px;
    color: #000000;
    font-weight: 700;
  }
  h2 {
    font-size: 18px;
    color: #000000;
  }
  h3 {
    font-size: 22px;
    color: #38716a;
    font-weight: 500;
  }
  h4 {
    font-size: 12px;
    color: #9b9b9b;
    font-weight: 400;
  }
}

.pointer {
  cursor: pointer;
}

@media (max-width: 1199px) {
  .values {
    margin-top: 25px;
  }
  .qck-link1 {
    text-align: left;
    margin-top: 10px;
    li {
      margin: 10px 15px 0 0 !important;
    }
  }
}
@media (min-width: 1200px) {
  .form-wrap {
    .header {
      h4 {
        display: inline-block;
        margin-right: 25px;
      }
      .form-inline {
        display: inline-block;
        .css-b62m3t-container {
          width: 250px;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .form-wrap {
    display: block !important;
    .header {
      h4 {
        display: block;
        margin-bottom: 10px !important;
      }
      .form-inline {
        display: block;
      }
      .react-datepicker-wrapper {
        margin: 10px 0;
      }
    }
  }
}

/* following code to hide Remove Arrows/Spinners */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.file-upload-container {
  width: 100%;
  border: 0.5px dashed #cacaca;
  background-color: #eaeaea;
}
.file-upload-container input[type="file"] {
  display: none;
}
.file-names {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
}
.bmt-btn-submit {
  background-color: #10a37f;
  border-radius: 2rem;
  padding: 10px 25px;
  box-shadow: none;
  font-size: 14px !important;
  margin: 0 5px;
  color: white;
}
.bmt-btn-submit:hover {
  background-color: #10a37f !important;
}
.bmt-btn-close {
  background-color: #eaeaea;
  border: 1px solid #175935;
  color: #000;
  border-radius: 2rem;
  padding: 10px 25px;
  box-shadow: none;
  font-size: 14px !important;
  margin: 0 5px;
}
.bmt-btn-close:hover {
  background-color: #eaeaea !important;
  color: #000 !important;
}
.itemsubmit {
  width: 180px;
  padding: 7px 25px !important;
}

.btn-round {
  border-radius: 6px;
  background: #eaeaea;
  margin-right: 10px;
  border: 0 none;
  width: 40px;
  height: 40px;
  text-align: center;
  &:hover {
    background: #eaeaea;
  }
}
.tab-button {
  width: 50%;
  border: 1px solid #cfcfcf;
  border-radius: 6px 6px 0 0;
  box-shadow: none;
  position: relative;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.icon-container {
  position: absolute;
  bottom: 5px;
  right: 5px;
  cursor: pointer;
}
.comment_icon {
  font-size: 16px;
  color: #cacaca;
  transition: color 0.3s ease;
}
.popup-order-content {
  z-index: 1000;
  position: absolute;
  top: calc(100% + 5px);
  transform: translateX(-90%);
  min-width: 200px;
  border: 1px solid #d8d7d7;
  padding: 10px 6px;
  background-color: #fff6f6;
  color: #777;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}
.popup-order-table {
  width: 100%;
  border-collapse: collapse;
}
.fixed-width {
  width: 100px;
  white-space: nowrap;
}
@media (max-width: 768px) {
  .popup-order-content {
    min-width: auto;
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
  }
}
